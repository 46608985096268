import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Container, Offcanvas } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useTranslation } from 'react-i18next';
import './navbar.scss';

import LanguageSwitcher from '../03_language/language';

const NavigationBar = () => {
    const { t } = useTranslation();
    const pathsInDropdown = ["/hunting", "/fishing", "/nature"];
    const [currentPath, setCurrentPath] = useState(window.location.pathname); 

    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const closeOffcanvas = () => setShowOffcanvas(false);

    const handleLinkClick = (path) => {
        setCurrentPath(path);
        closeOffcanvas();
    }

    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const visible = prevScrollPos > currentScrollPos;

            setIsVisible(visible);
            setPrevScrollPos(currentScrollPos);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [prevScrollPos]);

    useEffect(() => {
        const content = document.querySelector('.lapponia-silent');
        if (showOffcanvas) {
            content.classList.add('blur');
        } else {
            content.classList.remove('blur');
        }
    }, [showOffcanvas]);

    return (
        <Navbar 
            expand="md" 
            fixed="top" 
            variant="dark"
            style={{ opacity: isVisible ? 1 : 0, transition: 'opacity 0.5s' }} 
        >
            <LinkContainer to="/" onClick={() => handleLinkClick('/')}>
                <Navbar.Brand className='brandname'>Lapponia Silent</Navbar.Brand>
            </LinkContainer>

            <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={() => setShowOffcanvas(!showOffcanvas)}/>

            <Navbar.Offcanvas
                id="offcanvasNavbar"
                aria-labelledby="offcanvasNavbarLabel"
                placement="end"
                show={showOffcanvas}
                onHide={closeOffcanvas}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title id="offcanvasNavbarLabel">{t('navbar.menu')}</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <Nav className="justify-content-end flex-grow-1 pe-1">
                        <LinkContainer to="/" onClick={() => handleLinkClick('/')}>
                            <Nav.Link>{t('navbar.home')}</Nav.Link>
                        </LinkContainer>

                        <NavDropdown
                            title={t('navbar.activities')}
                            className={pathsInDropdown.includes(currentPath) ? 'dropdown-active' : 'dropdown'}
                        >
                            <LinkContainer to="/hunting" onClick={() => handleLinkClick('/hunting')} className={currentPath === "/hunting" ? 'dropdown-item-active' : 'dropdown-item'}>
                                <NavDropdown.Item>{t('navbar.hunting')}</NavDropdown.Item>
                            </LinkContainer>
                            
                            <LinkContainer to="/fishing" onClick={() => handleLinkClick('/fishing')} className={currentPath === "/fishing" ? 'dropdown-item-active' : 'dropdown-item'}>
                                <NavDropdown.Item>{t('navbar.fishing')}</NavDropdown.Item>
                            </LinkContainer>
                            
                            <LinkContainer to="/nature" onClick={() => handleLinkClick('/nature')} className={currentPath === "/nature" ? 'dropdown-item-active' : 'dropdown-item'}>
                                <NavDropdown.Item>{t('navbar.nature')}</NavDropdown.Item>
                            </LinkContainer>
                        </NavDropdown>

                        <LinkContainer to="/about" onClick={() => handleLinkClick('/about')}>
                            <Nav.Link>{t('navbar.about')}</Nav.Link>
                        </LinkContainer>

                        <LinkContainer to="/contact" className="last-link" onClick={() => handleLinkClick('/contact')}>
                            <Nav.Link>{t('navbar.contact')}</Nav.Link>
                        </LinkContainer>
                    </Nav>
                    
                    <Container className='navbar-language'>
                        <LanguageSwitcher />
                        <div className='languages'>
                            <p className='selected-language'>{t('navbar.selected-language')}</p>
                            <p className='language'>{t('navbar.language')}</p>
                        </div>
                    </Container>

                </Offcanvas.Body>
            </Navbar.Offcanvas>
        </Navbar>
    );
};

export default NavigationBar;

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import './transitions.scss';

const Transitions = ({ children }) => {
    const location = useLocation();
    const [fadeKey, setFadeKey] = useState(location.key);

    useEffect(() => {
        setFadeKey(location.key);
    }, [location.key]);

    return (
        <CSSTransition
            key={fadeKey}
            in={true}
            appear={true}
            timeout={500}
            classNames="fade"
        >
            <div className="fade-wrapper">{children}</div>
        </CSSTransition>
    );
};

export default Transitions;

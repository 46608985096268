import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// FLAGS //
import sv from '../../01_ASSETS/02_media/images/00_flags/flag_sv.webp';
import en from '../../01_ASSETS/02_media/images/00_flags/flag_en.webp';
import it from '../../01_ASSETS/02_media/images/00_flags/flag_it.webp';

import './language.scss';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
    setDropdownOpen(false);
  }

  const flagMap = {
    en: en,
    sv: sv,
    it: it
  };

  // This effect runs when dropdownOpen changes
  useEffect(() => {
    function handleClickOutside(event) {
      // If the user clicked outside of the dropdown menu, close it
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    }

    // When the dropdown is open, listen for click events
    if (dropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      // Otherwise, make sure to clean up the event listener
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  return (
    <div className='language-switcher-container' ref={dropdownRef}>
      <button className='language-toggler' onClick={() => setDropdownOpen(!dropdownOpen)}>
        <img
          className='current-flag'
          src={flagMap[i18n.language]}
          alt={i18n.language}
        />
      </button>

      {dropdownOpen && (
        <div className='switcher-dropdown'>
          {Object.keys(flagMap).filter(lang => lang !== i18n.language).map(lang => (
            <button key={lang} onClick={() => changeLanguage(lang)}>
              <img
                className='switcher-flag'
                src={flagMap[lang]}
                alt={lang}
              />
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;

import { useState, useEffect } from 'react';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import { useTranslation } from 'react-i18next';

import NavigationBar from './02_COMPONENTS/01_navbar/navbar.jsx';
import Loader from './02_COMPONENTS/09_loader/loader.jsx';
import Transitions from './02_COMPONENTS/08_transitions/transitions.jsx';

import './App.scss';

const Initial = lazy(() => import('./03_NAVIGATION/00_initial/initial.jsx'));
const Home = lazy(() => import('./03_NAVIGATION/01_home/home.jsx'));
const Hunting = lazy(() => import('./03_NAVIGATION/02_hunting/hunting.jsx'));
const Fishing = lazy(() => import('./03_NAVIGATION/03_fishing/fishing.jsx'));
const Nature = lazy(() => import('./03_NAVIGATION/04_nature/nature.jsx'));
const About = lazy(() => import('./03_NAVIGATION/05_about/about.jsx'));
const Contact = lazy(() => import('./03_NAVIGATION/06_contact/contact.jsx'));
const ThankYou = lazy(() => import('./03_NAVIGATION/07_thank-you/thank-you.jsx'));

function App() {
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const language = localStorage.getItem('language');
    
    if (language) {
      i18n.changeLanguage(language);
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [i18n]);

  if (loading) {
    return <Initial setLoading={setLoading} />;
  }

  return (
    <div className="App">
      <Router>
        <div className='lapponia-silent'>
          <NavigationBar />
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route path="/" element={<Transitions><Home /></Transitions>} />
              <Route path="/hunting" element={<Transitions><Hunting /></Transitions>} />
              <Route path="/fishing" element={<Transitions><Fishing /></Transitions>} />
              <Route path="/nature" element={<Transitions><Nature /></Transitions>} />
              <Route path="/about" element={<Transitions><About /></Transitions>} />
              <Route path="/contact" element={<Transitions><Contact /></Transitions>} />
              <Route path="/thank-you" element={<Transitions><ThankYou /></Transitions>} />
            </Routes>
          </Suspense>
        </div>
      </Router>
    </div>
  );
}

export default App;

import { useTranslation } from 'react-i18next';
import './loader.scss';

const Loader = () => {
  const { t } = useTranslation();

  const word = t('loader.loading');
  
  return (
    <div className="loader-container">
      <div className="lds-dual-ring"></div>
    </div>
  );
};

export default Loader;
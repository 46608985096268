import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './LOCALES/en/translation.json';
import svTranslation from './LOCALES/sv/translation.json';
import itTranslation from './LOCALES/it/translation.json';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    sv: { translation: svTranslation },
    it: { translation: itTranslation },
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
